import { Enum } from '@/utils'

export const Degree = new Enum({
  2: '大专',
  3: '本科',
  4: '硕士',
  5: '博士',
	6: '博士后',
	7: 'MBA',
	8: '中专/中技',
  9: '高中',
  10: '初中'
}, [10, 9, 8, 2, 3, 4, 5, 6, 7]);

//学历picker
export const DegreeOption = [
  {name: '初中', value: 10},
	{name: '高中', value: 9},
	{name: '中专/中技', value: 8},
	{name: '大专', value: 2},
	{name: '本科', value: 3},
	{name: '硕士', value: 4},
	{name: '博士', value: 5},
	{name: '博士后', value: 6},
	{name: 'MBA', value: 7},
]

// 公司规模
export const Scale = {
	1: '1-49人',
	2: '50-99人',
	3: '100-499人',
	4: '500-999人',
	5: '1000-9999人',
	6: '10000人以上'
};

// 熟练程度
export const Proficiency = {
	1: '简单沟通',
	2: '读写精通',
	3: '商务洽谈',
	4: '同声翻译'
};

// 语言能力
export const Language = {
	1: '英语',
	2: '日语',
	3: '其他'
};

//语言等级
export const LanguageGrade = {
	1: '专业四级',
	2: '专业八级',
	3: 'CET4',
	// 4: 'CET5',
	5: 'CET6',
	6: 'PETS-1B',
	7: 'PETS-2',
	8: 'PETS-3',
	9: 'PETS-4',
	10: 'PETS-5',
	11: 'N1',
	12: 'N2',
	13: 'N3',
	14: 'N4',
	15: 'N5'
};

export const WorkField = [
	'workTime',
	'companyName',
	'jobName',
	'companyIndustry',
	'scale',
	'department',
	'reportPerson',
	'underPerson',
	'companyIntroduce',
	'workContent',
	'workAchievement',
	'dimissionReason',
	'internshiplag'
];
export const EduField = [
	'eduTime',
	'graduationSchool',
	'degree',
	'professional'
];

export const ProjectField = [
	'projectTime',
	'projectName',
	'projectDesc',
	'responsibilityDesc'
];

export const LanguageField = [
	'languages'
];
export const TrainingExpsField = [
	'trainingTime',
	'trainingOrganization',
	'trainingName',
	'trainingPlace',
	'trainingCertificate',
	'trainingDesc',
];

export default {
  Degree,
	DegreeOption,
  Proficiency,
  Scale,
	Language,
	LanguageGrade,
	WorkField,
	EduField,
	ProjectField,
	LanguageField,
	TrainingExpsField
}

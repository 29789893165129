import * as types from '../types'
import { local } from '../../utils'
// api
import * as baseData from '@/utils/api'

let user = local.get('user') ? JSON.parse(local.get('user')) : {};
let name = local.get('user') ? JSON.parse(local.get('user')).name : {};
let personId = local.get('user') ? JSON.parse(local.get('user')).personId : {};
let companyId = local.get('user') ? JSON.parse(local.get('user')).companyId : {};
let mobile = local.get('user') ? JSON.parse(local.get('user')).mobile : {};
let externalFlag = local.get('user') ? JSON.parse(local.get('user')).externalFlag : {};
let authStatus = local.get('user') ? JSON.parse(local.get('user')).authStatus : {};
let email = local.get('user') ? JSON.parse(local.get('user')).email : {};

const state = {
  user,
  name,
  personId,
  companyId,
  mobile,
  externalFlag,
  authStatus,
  email
};

// getters
const getters = {
  user: state => state.user,
  name: state => state.name,
  personId: state => state.personId,
  companyId: state => state.companyId,
  mobile: state => state.mobile,
  externalFlag: state => state.externalFlag,
  authStatus: state => state.authStatus,
  email: state => state.email
}

const mutations = {
  [types.SET_USER_INFO]:(state, data) => {
    local.set('user', JSON.stringify(data));
    state.user = data;
    state.name = data.name;
    state.personId = data.personId;
    state.companyId = data.companyId;
    state.mobile = data.mobile;
    state.externalFlag = data.externalFlag;
    state.authStatus = data.authStatus;
    state.email = data.email;
  },
  [types.CLEAR_USER_INFO]:(state) => {
    local.remove('user');
    local.remove('oldPersonId');
    state.user = {};
    state.name = '';
    state.personId = '';
    state.mobile = '';
    state.companyId = '';
    state.externalFlag = '';
    state.authStatus = '';
    state.email = '';
  },
};

const actions = {
  getUserInfo({ commit }, queryParams) {
    return baseData.getUserInfo(queryParams).then(data => {
      if(data) {
        commit(types.SET_USER_INFO, data);
      }
    })
  }
};

export default {
  state,
  getters,
  mutations,
  actions
}
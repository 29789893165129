<template>
  <el-dialog
    :title="`${title}（最多选择${max}个）`"
    :visible.sync="isShow"
    center
    class="dialog-components-functions">
    <div class="data-selector">
      <div class="selected" :class="{ 'has-search':searchable }">
        <span class="title">选项：</span>
        <div class="selected-box">
          <label class="selected-item" v-for="(code, key) in selectedVal" :key="key">
            <span>{{ code | FUNCTION }}</span>
            <i class="h5pp h5pp-icon-remove" @click="delSelectedItem(code)"></i>
          </label>
        </div>
        <!--search code-->
        <!-- <div v-if="searchable" class="search-ipt-box">
          <a-auto-complete
            v-model.trim="searchKeyword"
            placeholder="请输入关键字"
            dropdown-class-name="popup-function-search-keyword-box"
            allowClear
            option-label-prop="label"
            @change="getSearchOption"
            @select="selectSearchOption">
            <a-input :maxLength="30" class="border-no-change">
              <span slot="suffix" class="suffix-search" @click.stop="selectSearchOption"></span>
            </a-input>
            <template slot="dataSource">
              <template v-if="searchDataList && searchDataList.length">
                <a-select-option
                  v-for="obj in searchDataList"
                  :key="`${obj.code}`"
                  :label="obj.name"
                  :value="`${obj.code}`"
                  class="matching-jobs clearfix">
                  <div>
                    <p class="name" v-html="highLightKeyWord(obj.name)"></p>
                    <p class="sub-name el-sl">{{ obj.parentsNodeStr }}</p>
                  </div>
                </a-select-option>
              </template>
            </template>
          </a-auto-complete>
        </div> -->
      </div>
      <div class="main-content">
        <aside class="data-root">
          <ul class="root-list">
            <li 
              v-for="(item, key) in firstFunctions" 
              :key="key" 
              @click="selectRoot(item)"
              :class="{ active: selectingCode.includes(parseInt(item.code)) }">
              {{item.name}}
            </li>
          </ul>
        </aside>
        <section class="data-box">
          <ul class="data-list clearfix" v-for="(child, key) in nodeLine" :key="key">
            <li 
              v-for="(item, index) in child"
              :key="index" 
              class="data-item"
              @click="selectChildNode(item, key)"
              :class="{ active: selectingCode.includes(parseInt(item.code)) }">
              <label><i class="h5pp h5pp-icon-open"></i><i class="h5pp h5pp-icon-put-away"></i><span>{{item.name}}</span></label>
            </li>
            <li class="data-children" :class="{ active: key === lineKey }" :key="`child${key}`">
              <ul class="children-data-list clearfix">
                <li 
                  class="children-item"
                  v-for="(item, index) in childNode"
                  :key="index"
                  :class="{ active: selectedCodes.includes(parseInt(item.code)) }"
                  @click="selectFunction(item)">
                  <label>{{item.name}}</label>
                </li>
               
              </ul>
            </li>
          </ul>
        </section>
      </div>
    </div>
    <template slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleOk">确定</el-button>
      <el-button type="default" @click="isShow = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import './main.scss'
  import { Dialog, Button, Message} from 'element-ui'

  export default {
    name: 'Functions',
    components: {
      [Dialog.name]: Dialog,
      [Button.name]: Button,
      [Message.name]: Message
    },
    data() {
      return {
        isShow: true,
        max: 1,
        title: '选择职位类别',
        node: [],
        childNode: [],
        selecting: '',
        selectedVal: [],
        lineKey: null,
        searchable: false, //开启搜索
        searchKeyword: '',
        searchDataList: [],
        callback: function() {}
      }
    },
    computed: {
      firstFunctions() {
        let data = this.$local.getObject('functions') || [];
				let newArr = [];
				data.forEach(item => {
					if(item.code < 100) {
						newArr.push(item);
					}
				});
				return newArr;
      },
      selectingCode() {
        return this.splitCode(this.selecting);
      },
      selectedCodes() {
        let codes = [];
        this.selectedVal.forEach(code => {
          codes = codes.concat(this.splitCode(code));
        });
        return codes;
      },
      nodeLine() {
        let lineData = [];
        let length = Math.ceil(this.node.length / 3);
        for(let i = 1; i <= length; i ++) {
          lineData.push(this.node.slice((i - 1) * 3, i * 3));
        }
        return lineData;
      }
    },
    methods: {
      init(opt) {
        this.isShow = true;
        this.max = opt.max ? opt.max : this.max;
        this.title = opt.title ? opt.title : this.title;
        this.searchable = opt.searchable ? opt.searchable : this.searchable;
        this.callback = opt.callback ? opt.callback : this.callback;
        this.dafaultSelect(opt.defaultVal);
        //job add模块 需求是每次进来初始化searchKeyword
        this.searchKeyword = '';
      },
      dafaultSelect(codes) {
        this.selecting = '';
        this.node = [];
        this.childNode = [];
        this.selectedVal = (codes && codes.length) ? codes.concat() : [];
      },
      handleOk() {
        this.isShow = false;
        this.callback(this.selectedVal);
      },
      selectRoot(item) {
        if(this.selectingCode[0] != item.code) {
          this.selecting = item.code;
          if(item.children && item.children.length > 0) {
            this.node = item.children;
          }
          this.lineKey = null;
        }
      },
      selectChildNode(item, key) {
        if(this.selecting != item.code) {
          this.selecting = item.code;
          if(item.children && item.children.length > 0) {
            this.childNode = item.children;
          }
          this.lineKey = key;
        } else {
          this.selecting = this.selectingCode[0];
          this.childNode = [];
          this.lineKey = null;
        }
      },
      selectFunction(item) {
        if(this.selectedVal.includes(item.code)) {
          this.delSelectedItem(item.code);
        } else {
          if(this.max === 1 && this.selectedVal.length >= this.max) {
            this.selectedVal = [];
            this.selectedVal.push(item.code);
            return;
          } else if(this.max > 1 && this.selectedVal.length === this.max) {
            Message.error(`最多选${this.max}项`);
            return false;
          }
          if(this.selectedVal.length === 1 &&  this.max === 1) {
            this.selectedVal.pop();
          }
          this.selectedVal.push(item.code);
        }
      },
      delSelectedItem(code) {
        this.selectedVal.splice(this.selectedVal.indexOf(code), 1);
      },
      splitCode(code = '') {
        code = `${code}`;
        let deep = code.length / 2;
        let codes = [];
        for(deep; deep > 0; deep--) {
          codes.push(parseInt(code.substr(0, deep * 2)))
        }
        return codes.reverse();
      },
      //关键字查找
      //后端说不好实现，所以在前端利用本地数据过滤进行实现
      async getSearchOption(keyword) {
        clearTimeout(inputTimer);
        if(!keyword) {
          this.searchDataList = [];
          return;
        }
        var inputTimer = setTimeout(()=>{
          let result = [];
          let dataListAll = JSON.parse(localStorage.getItem('functions'));
          //查找第三层数据
          //查找parentNode的名字
          for(let item of dataListAll) {
            //查找第三层数据
            let tempItem = Object.assign({},item);
            if(!item.children && item.name.indexOf(keyword) >= 0) {
              result.push(tempItem);
            }else {
              continue;
            }
            let parentId = item.parentCode;
            for(let item2 of dataListAll) {
              if(item2.code == parentId){
                tempItem['parentsNodeStr'] = !tempItem['parentsNodeStr'] ? item2.name : item2.name + ' > ' + tempItem['parentsNodeStr']; 
                //为4说明 还需要向上查找1层
                if(String(item.parentCode).length === 4) {
                  for(let item3 of dataListAll) {
                    let level1Id = item2.parentCode;
                    if(item3.code == level1Id){
                      tempItem['parentsNodeStr'] = !tempItem['parentsNodeStr'] ? item3.name : item3.name + ' > ' + tempItem['parentsNodeStr'];
                    }
                  }
                }
              }
            }
          }
          this.searchDataList = result;
        },800)
      },
      selectSearchOption(code) {
        this.selectFunction({code})
        //需求：模糊搜索选中的同时，勾选对应的三个层级菜单
        //node childNode selecting
        let level1Code = code.slice(0,2);
        let level2Code = code.slice(0,4);
        let dataListAll = JSON.parse(localStorage.getItem('functions'));
        this.selecting = code;
        //这个lineKey代表是第几行 （下边的3代表 1行排列3个标签）
        this.lineKey = Math.ceil(parseInt(code.slice(2,4)) / 3) - 1;  
        //查找第三层数据
        //查找parentNode的名字
        for(let item of dataListAll) {
          if(item.code == level1Code) {
            this.node = item.children;
          }
          if(item.code == level2Code) {
            this.childNode = item.children;
          }
        }
      },
      //搜索高亮
      highLightKeyWord(val) {
        if(!val)return '';
        return val.replace(new RegExp(this.searchKeyword,'g'),'<span class=\"highlight\">'+this.searchKeyword+'</span>');
      }
    }
  }
</script>
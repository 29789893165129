<template>
  <el-dialog 
    title="全部地区"
    :visible.sync="isShow"
    @cancel="isShow = false"
    width="900"
    centered
    class="dialog-components-area">
    <div class="area-picker">
      <div class="area-head clearfix">
        <div class="selectd-item title">最多可选{{max}}个：</div>
        <ul class="selectd-item citys">
          <li v-for="(item, index) in selectedVals" :key="item">
            <span>{{ dataRepeat[item].name }}</span>
            <i class="el-icon-close" @click="remove(index, item)"/>
          </li>
        </ul>
      </div>
      <div class="area-body">
        <ul class="area-nav">
          <li v-for="(item, index) in tabArr" :key="index">
            <a href="javascript:;" @click="changeTab(index)">{{item.name}}</a>
          </li>
        </ul>
        <div class="area-content-wrap">
          <!-- 第一帧 -->
          <div class="area-level-wrap" v-if="tabArr.length === 1">
            <div class="area-level level-hot-citys" v-if="isShowHotCity">
              <div class="title"><h4>热门城市</h4></div>
              <ul class="citys-ul">
                <li v-for="item in dataHot" :key="item.code" :class="{ active: dataRepeat[item.code].selected }">
                  <a href="javascript:;" @click="selected(1, item.code)">{{item.name}}</a>
                </li>
              </ul>
            </div>
            <div class="area-level level-provinces">
              <div class="title"><h4>全部省份</h4></div>
              <ul class="citys-ul">
                <template v-for="item in data">
                  <li v-if="![810000, 820000, 830000, 840000].includes(item.code)" :key="item.code" :class="{ active: dataRepeat[item.code].selected }">
                    <a href="javascript:;" @click="changeLevel(item)">
                      <span>{{item.name}}</span>
                      <span class="selected-cnt" v-show="dataRepeat[item.code] && (!dataRepeat[item.code].selected && dataRepeat[item.code].total)">{{ dataRepeat[item.code].total }}</span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <div class="area-level level-provinces">
              <div class="title"><h4>港澳台及国外</h4></div>
              <ul class="citys-ul">
                <template v-for="obj in data">
                  <template v-if="[810000, 820000, 830000, 840000].includes(obj.code)">
                    <li v-for="item in obj.children" :key="item.code" :class="{ active: dataRepeat[item.code].selected }">
                      <a href="javascript:;" @click="selected(2, item.code, item)">
                        <span>{{item.name}}</span>
                        <span class="selected-cnt" v-show="dataRepeat[item.code] && (!dataRepeat[item.code].selected && dataRepeat[item.code].total)">{{ dataRepeat[item.code].total }}</span>
                      </a>
                    </li>
                  </template>
                </template>
              </ul>
            </div>
          </div>
          <!-- 第二帧 -->
          <div class="area-level-wrap" v-else-if="tabArr.length === 2">
            <div class="area-level level-citys">
              <div class="title" v-if="chooseScope > 0 && chooseScope !== 2">
                <h5 :class="{ active: dataRepeat[tabArr[1].code].selected }" @click="selectedParent(dataRepeat[tabArr[1].code].code, true)">{{'全' + dataRepeat[tabArr[1].code].name}}</h5>
                <span class="selected-num" v-show="dataRepeat[tabArr[1].code] && dataRepeat[tabArr[1].code].total">{{dataRepeat[tabArr[1].code].total}}</span>
              </div>
              <ul class="citys-ul">
                <li v-for="item in tabArr[1].children" :key="item.code" :class="{ active: dataRepeat[item.code].selected }">
                  <a href="javascript:;" @click="selected(2, item.code, item)">
                    <span>{{item.name}}</span>
                    <span class="selected-cnt" v-show="dataRepeat[item.code] && (!dataRepeat[item.code].selected && dataRepeat[item.code].total)">{{ dataRepeat[item.code].total }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- 第三帧 -->
          <div class="area-level-wrap" v-else-if="tabArr.length === 3">
            <div class="area-level level-citys">
              <div class="title" v-if="chooseScope > 0 && chooseScope !== 2">
                <h5 :class="{ active: dataRepeat[tabArr[2].code].selected }" @click="selectedParent(dataRepeat[tabArr[2].code].code)">{{dataRepeat[tabArr[2].code].name}}</h5>
                <span class="selected-num" v-show="dataRepeat[tabArr[2].code] && dataRepeat[tabArr[2].code].total">{{dataRepeat[tabArr[2].code].total}}</span>
              </div>
              <ul class="citys-ul">
                <li v-for="item in tabArr[2].children" :key="item.code">
                  <a href="javascript:;" @click="selected(3, item.code, item)">{{item.name}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button type="primary" @click="onSave">确定</el-button>
      <el-button @click="isShow = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { Dialog, Button, Icon, Message} from 'element-ui'
  import './main.scss'

  export default {
    name: 'Area',
    components: {
      [Dialog.name]: Dialog,
      [Button.name]: Button,
      [Icon.name]: Icon,
      [Message.name]: Message
    },
    data() { 
      return {
        isShow: true,
        max: 1, //默认最多选择1个
        data: this.$utils.deepClone(this.$local.getObject('locations')),
        dataRepeat: this.$utils.deepClone(this.$local.getObject('locationsObj')),
        dataHot: this.$utils.deepClone(this.$local.getObject('hotLocations')).splice(0, 14),
        tabArr: [{ name: '全部', code: 1 }],
        selectedVals: [],
        showLevel: 2, //显示到第几级；1-省；2-市；3-县；默认显示到市
        threeLevelChild: {}, //三级选择
        chooseScope: 3, // 0: 都不显示 1:显示省 2:显示市 3：全部显示
        isShowHotCity: true, //是否显示热门城市
        isClickAll: true, //是否可以选全+省份
        callback: function() {}
      };
    },
    computed: {
      tips() {
        return this.max == 1 ? '单选' : '最多可选' + this.max + '个';
      }
    },
    methods: {
      init(opt) {
        this.isShow = true;
        this.max = opt.max || this.max;
        this.chooseScope = opt.chooseScope >= 0 ? opt.chooseScope : this.chooseScope;
        this.showLevel = opt.showLevel || this.showLevel;
        this.isShowHotCity = typeof opt.isShowHotCity === 'boolean' ? opt.isShowHotCity : this.isShowHotCity;
        this.isClickAll = typeof opt.isClickAll === 'boolean' ? opt.isClickAll : this.isClickAll;
        this.callback = opt.callback || this.callback;
        this.defaultSelect(opt.defaultVal);
      },
      // 等级
      changeLevel(data) {
        this.tabArr.push(data);
      },
      changeTab(index) {
        this.tabArr.splice(index + 1);
      },
      selected(tabLevel, code, threeItem) {
        // [省,市,区]
        if(this.showLevel === 3) {
          // 热门
          if(tabLevel === 1) {
            this.selectedVals.forEach(item => {
              this.dataRepeat[item].selected = false;
              this.dataRepeat[item].total = 0;
            });
            this.selectedVals = [];
            if(this.dataRepeat[code] && this.dataRepeat[code].parentCode) {
              this.dataRepeat[this.dataRepeat[code].parentCode].selected = true;
              this.selectedVals.unshift(this.dataRepeat[code].parentCode);
            }
          }
        } else {
          // 过滤已选的父级
          this.selectedVals = this.selectedVals.filter(item => {
            if(!this.dataRepeat[code]) {
              return false;
            }
            if (this.dataRepeat[code].parentCode == item) {
              this.dataRepeat[this.dataRepeat[code].parentCode].selected = false;
              this.dataRepeat[this.dataRepeat[code].parentCode].total = 0;
            }
            return this.dataRepeat[code].parentCode != item;
          });
          if(this.max === 1 && this.selectedVals.length >= this.max) {
            this.selectedVals = [];
            this.selectedVals.push(code);
            return;
          } else if(!this.dataRepeat[code].selected && this.selectedVals.length >= this.max) {
            Message.error(`最多选择${this.max}个`);
            return;
          }
        }

        if(tabLevel === 2 && this.showLevel === 3) {
          this.tabArr.push(threeItem);
          if(threeItem.hasOwnProperty('children') && threeItem.children.length) {
            this.threeLevelChild = threeItem['children'];
          }
        } else {
          let parent = this.dataRepeat[this.dataRepeat[code].parentCode];
          if (!parent.total) {
            parent.total = 0;
          }
          if(this.dataRepeat[code].selected) {
            parent.total > 0 ? parent.total -= 1:'';
            this.dataRepeat[code].selected = false;
            this.selectedVals.splice(this.selectedVals.indexOf(code), 1);
          } else {
            if(this.max === 1 && this.selectedVals.length >= this.max) {
              this.selectedVals = [];
              this.selectedVals.push(code);
              return;
            } else if(!this.dataRepeat[code].selected && this.selectedVals.length >= this.max) {
              Message.error(`最多选择${this.max}个`);
              return;
            }
            // [省,市,区]
            if (this.showLevel === 3 && this.selectedVals[2] && this.dataRepeat[this.selectedVals[2]]) {
              this.dataRepeat[this.selectedVals[2]].selected = false;
              this.selectedVals = this.selectedVals.slice(0, 2);
              parent.total = 0;
            }
            parent.total += 1;
            this.dataRepeat[code].selected = true;
            this.selectedVals.push(code);
          }
        }
      },
      selectedParent(code, isAll) {
        if(isAll && !this.isClickAll) {
          return;
        }
        // 过滤已选的父级code是否跟当前选中的一致
        this.selectedVals = this.selectedVals.filter(item => {
          if(this.dataRepeat[item].parentCode == code) {
            this.dataRepeat[item].selected = false;
            this.dataRepeat[code].total > 0 ? this.dataRepeat[code].total -= 1 : '';
          }
          return this.dataRepeat[item].parentCode != code;
        });
        if(this.max === 1 && this.selectedVals.length >= this.max) {
          this.selectedVals = [];
          this.selectedVals.push(code);
          return;
        } else if(!this.dataRepeat[code].selected && this.selectedVals.length >= this.max) {
          Message.error(`最多选择${this.max}个`);
          return false;
        }
        if(this.dataRepeat[code].selected) {
          this.dataRepeat[code].selected = false;
          this.selectedVals.splice(this.selectedVals.indexOf(code), 1);
        } else {
          this.dataRepeat[code].selected = true;
          this.selectedVals.push(code);
        }
      },
      remove(index, code) {
        if(this.dataRepeat[code].parentCode) {
          let parent = this.dataRepeat[this.dataRepeat[code].parentCode];
          parent.total > 0 ? parent.total -= 1 : '';
        }
        this.dataRepeat[code].selected = false;
        this.selectedVals.splice(index, 1);
      },
      onSave() {
        if(this.selectedVals.length) {
          this.callback(this.selectedVals);
          this.isShow = false;
        } else {
          Message.error('请至少选择1个');
        }
      },
      defaultSelect(data) {
        this.selectedVals = [];
        this.tabArr = [{ name: '全部', code: 1 }];
        // 重新赋值
        this.dataRepeat = this.$utils.deepClone(this.$local.getObject('locationsObj'));

        data && data.length && data.forEach(item => {
          let upper = this.dataRepeat[item] && this.dataRepeat[item].parentCode;
          if(upper && this.dataRepeat[upper].parentCode) { //回显区
            let townParent = this.dataRepeat[upper]; //市
            if(!townParent.total) {
              townParent.total = 0;
            }
            townParent.total += 1;

            let provinceParent = this.dataRepeat[townParent.parentCode]; //省
            if(!provinceParent.total) {
              provinceParent.total = 0;
            }
            provinceParent.total += 1;
          } else if(upper) { //回显市
            let parent = this.dataRepeat[upper];
            if(!parent.total) {
              parent.total = 0;
            }
            parent.total += 1;
          }
          this.dataRepeat[item].selected = true;
          this.selectedVals.push(item);
        });
      }
    }
  };
</script>
/*
======
职位信息
======
*/
// 职位列表
const JobList = () => import(/* webpackChunkName: 'job' */ '@/views/job/list');
// 职位详情
const JobDetail = () => import(/* webpackChunkName: 'job' */ '@/views/job/detail');

export default [
  {
    path: 'list',
    name: 'jobList',
    component: JobList,
    meta: {
      title: '在招职位'
    }
  },
  {
    path: 'detail/:id',
    name: 'jobDetail',
    component: JobDetail,
    meta: {
      title: '职位详情'
    }
  }
]
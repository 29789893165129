// Vue.prototype.$brand = 'aimsen'
const install = (Vue) => {
  Vue.prototype.$stat = (code, data) => {
    let userId = localStorage.getItem('user') ?  JSON.parse(localStorage.getItem('user')).personId : '';
    try {
      window._sendLogMonitor_pvuv && window._sendLogMonitor_pvuv(`20013${code}`, userId, data);
    } catch(err) {
      console.error('调用统计出错：', err);
    }
  }
}
export default install;
/*
======
入职指南
======
*/
// 登录
const Login = () => import(/* webpackChunkName: 'guide' */ '@/views/guide/login');
// 首页
const Home = () => import(/* webpackChunkName: 'guide' */ '@/views/guide/home');
// 登记表
const Register = () => import(/* webpackChunkName: 'guide' */ '@/views/guide/register');
// 完成
const Finish = () => import(/* webpackChunkName: 'guide' */ '@/views/guide/finish');
// 添加自定义模块
const CustomExp = () => import(/* webpackChunkName: 'resume' */ '@/views/guide/customExp');

export default [
  {
    path: 'login',
    name: 'guideLogin',
    component: Login,
    meta: {
      title: '入职指南'
    }
  },
  {
    path: 'register/:id',
    name: 'guideRegister',
    component: Register,
    meta: {
      title: '入职指南',
      keepAlive: true
    }
  },
  {
    path: 'finish',
    name: 'guideFinish',
    component: Finish,
    meta: {
      title: '入职指南'
    }
  },
  {
    path: 'customExp',
    name: 'guideCustomExp',
    component: CustomExp,
    meta: {
      title: '入职指南'
    }
  },
  {
    path: ':url',
    name: 'guideHome',
    component: Home,
    meta: {
      title: '入职指南'
    }
  }
]
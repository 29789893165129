<template>
  <el-button 
    class="ppy-comps-button" 
    :type="type" 
    :size="size" 
    :disabled="disabled"
    :loading="loading"
    :style="width ? `--w:${width}px;--x:0px;--y:0px;` : '--w:0px;--x:0px;--y:0px;'"
    :class="{
      'ppy-button-animation-wave': hasAnimation.includes(1) && defaultAnimationFlag, 
      'ppy-button-animation-iconloop': hasAnimation.includes(2) && defaultAnimationFlag,
      'ant-btn-circle': shape === 'circle', 
      'ppy-comps-button-width': width
    }"
    @mousedown.native="$emit('mousedown')"
    @mouseup.native="$emit('mouseup')"
    @mouseleave.native="$emit('mouseleave')"
    @mouseenter.native="onMouseEnter"
    @click="handleClick">
    <div v-show="hasAnimation.includes(1) && defaultAnimationFlag" class="vs-button-backgroundx vs-button--background"></div>
    <p class="but-inner"><slot></slot></p>
  </el-button>
</template>

<script>
  import './index.scss'
  import { Button } from 'element-ui'

  export default {
    components: {
      [Button.name]: Button
    },
    name: 'PButton',
    props: {
      type: {
        type: String,
        default: 'default'
      },
      size: {
        type: String,
        default: 'default'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      shape: {
        type: String,
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      },
      hasAnimation: { // 支持传多个动画。 1-按钮背景变化 2-icon动画
        type: Array,
        default: () => [1, 2]
      },
      width: { // 固定宽度属性，比如确定，取消
        type: Number,
        default: null
      }
    },
    computed: {
      defaultAnimationFlag() {
        if(this.disabled || this.type === 'link' || this.shape == 'circle' || this.loading) {
          return false;
        }
        return true;
      }
    },
    mounted() {
      // iconloop 动画处理
      if(this.hasAnimation.includes(2) && this.defaultAnimationFlag) {
        var elements = this.$el.children;
        Array.prototype.forEach.call(elements, function (obj, index) {
          if(obj.className && obj.className.length && obj.className.split(' ').includes('ppy')) {
            let outerHTML = obj.outerHTML;
            obj.outerHTML = `<div class='animation-iconloop'>${outerHTML}${outerHTML}</div>`;
          }
        });
      }
    },
    methods: {
      handleClick(e) {
        this.$emit('click', e);
      },
      onMouseEnter(e) {
        const { left, top } = (e.toElement || event.target).getBoundingClientRect();
        // TODO IE 不支持需兼容处理
        e.target.style = `--x:${e.clientX - left}px;--y:${e.clientY - top}px;--w:${this.width ? this.width : e.target.offsetWidth }px`;
        this.$emit('mouseenter');
      }
    }
  };
</script>
/**
 * Vue的插件，用来获取和设置localStorage存储
 **/
 import store from '@/store'

export const local = {
  set(key, value) {
    window.localStorage[key] = value;
  },
  //读取单个属性
  get(key, defaultValue) {
    return window.localStorage[key] || defaultValue;
  },
  //存储对象，以JSON格式存储
  setObject(key, value) {
    window.localStorage[key] = JSON.stringify(value);
  },
  //读取对象
  getObject(key) {
    return JSON.parse(window.localStorage[key] || '{}');
  },
  //删除单个对象
  remove(key) {
    window.localStorage.removeItem(key);
  },
  //删除所有
  removeAll() {
    window.localStorage.clear();
  }
};

/**
 * util 常用方法
 **/
export const utils = {
  // 拷贝
  copy(data) {
    return data ? JSON.parse(JSON.stringify(data)) : data;
  },
  deepClone(obj) {
    if(obj === null || typeof obj !== 'object') {
      return obj;
    }
    if(obj instanceof Array) {
      let copy = [];
      for(let i = 0; i < obj.length; i++) {
        copy.push(utils.deepClone(obj[i]));
      }
      return copy;
    }
    if(obj instanceof Date) {
      let copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if(obj instanceof Object) {
      let copy = {};
      for(let key in obj) { //递归
        if(obj.hasOwnProperty(key)) {
          copy[key] = utils.deepClone(obj[key]);
        }
      }
      return copy;
    }
  },
  //多维数组 转 一维 对象
  repeat(data, name) {
    let obj = {};
    let format = (data) => {
      for(let i = 0, j = data.length; i < j; i++) {
        let item = this.copy(data[i]);
        item.children = null;
        obj[item[name]] = item;

        data[i].children && format(data[i].children);
      }
    }
    format(data);
    return obj;
  },
  DATE(date, fmt) {
    if (!date) {
      return '';
    }
    date = new Date(date * 1);
    if(/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    };
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
      }
    }
    return fmt;
  },
  isPhone() {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
  //删除没有值的属性
  deleteKeys(query, delVals='') {
    for (let item in query) {
      if ([undefined, '', null, NaN, -9999, ...delVals].includes(query[item])){
        delete query[item];
      }
    }
    return { ...query };
  },
  // base64转buffer
  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  },
  scrollToTop() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	},
  sendStat(code, data = {}) {
    let companyId = store.getters.uccnInfo.companyId || '';
    try {
      if(_sendLogMonitor_pvuv) {
        _sendLogMonitor_pvuv(`20013${code}`, companyId, data);
      }
    } catch(err) {
      console.warn('调用统计出错：', err);
    }
  },
  // 数组中的所有数据都没有则不展示
  isShowField(arr = []) {
    return arr.some(ele => {
      if(Array.isArray(ele)) {
        if(ele.length) {
          return ele;
        }
      } else if(ele) {
        return ele;
      }
    });
  },
  // 是否含有其中某一项则展示
  isHasField(arr, fieldArr = []) {
    let result = false;
    fieldArr.forEach(ele => {
      if(arr.includes(ele)) {
        result = true;
      }
    });
    return result;
  },
  // 将传过来的对象里数组转成string
	formatDataArr(data) {
    const EMPTIES = [undefined, null, NaN, ''];
		let dataStr = {};
		for(const [key, value] of Object.entries(data)) {
      if(Array.isArray(value)) {
        if(value.length) dataStr[key] = value.join(',');
      } else if(!EMPTIES.includes(value)) {
        dataStr[key] = value;
      }
		}
		return Object.keys(dataStr).length ? dataStr : null;
  },
  getUrlParams(queryName) {
    let regExp = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)', 'i');
    let regTxt = window.location.search.substr(1).match(regExp);
    if(regTxt != null){
      return decodeURI(regTxt[2]);
    } else {
      return null;
    }
  },
};
export class Enum {
  oriEnumObj
  length
  constructor(enumObj, sequence) {
    if(!this._isPlainObject(enumObj)) {
      throw new Error(`Enum Invalid Argument: type check failed for argument "enumObj". Expected Object, got ${this._getVarType(enumObj)}`);
    }

    this._init(enumObj, sequence);
  }
  sort(sequence) {
    if(!Array.isArray(sequence)) {
      throw new Error(`Enum Invalid Argument: type check failed for argument "sequence". Expected Array, got ${this._getVarType(sequence)}`);
    }
    return new Enum(this.oriEnumObj, sequence);
  }
  _init(enumObj, sequence) {
    this.oriEnumObj = { ...enumObj };
    this.length = Object.keys(enumObj).length;
    let _sequence = sequence ? sequence : Object.keys(enumObj);
    _sequence.forEach(key => {
      this[key] = enumObj[key];
      this[enumObj[key]] = key;
    });

    this[Symbol.iterator] = () => {
      let i = 0;
      return {
        next() {
          let done = i >= _sequence.length;
          let value = !done ? enumObj[_sequence[i++]] : undefined;
          return {
            done,
            value
          }
        }
      }
    }

    const _config = {};
    for (const key in this) {
      Object.assign(_config, { [key]: {
        enumerable: this.oriEnumObj.hasOwnProperty(key)
      }})
    }
    Object.defineProperties(this, _config);
  }
  _isPlainObject(val) {
    return this._getVarType(val) === 'Object';
  }
  _getVarType(val) {
    return Object.prototype.toString.call(val).replace(/\[.*?\s(.*?)\]/, '$1');
  }
}

export default {
  install: function (vm) {
    vm.prototype.$local = local;
    vm.prototype.$utils = utils;
  }
}
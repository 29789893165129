/**
 * 定义状态树
 */
export default {
  // 热门城市
  hotLocations: [],
  // 城市
  locations: [],
  // 城市平铺 
  locationsObj: {},
  // 行业
  industrys: [],
  // 行业平铺
  industrysObj: {},
  // 职能
  functions: [],
  // 职能平铺
  functionsObj: {},
  // 学历
  degrees: [],
  // 学历平铺
  degreesObj: {},
  // 工作年限
  workYears: [],
  // 工作年限平铺
  workYearsObj: {},
  // 职位学历要求
  educations: [],
  // 职位学历要求平铺
  educationsObj: {},
  // 公司规模
  scales: [],
  // 公司规模平铺
  scalesObj: {},
  // 语言
  languages: []
}
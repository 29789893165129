/*
======
记录信息
======
*/
// 推荐码记录
const RecordReferralCode = () => import(/* webpackChunkName: 'record' */ '@/views/record/referralCode');
// 推荐记录
const RecordNominate = () => import(/* webpackChunkName: 'record' */ '@/views/record/nominate');

export default [
  {
    path: 'referralcode',
    name: 'recordReferralCode',
    component: RecordReferralCode,
    meta: {
      title: '推荐码记录'
    }
  },
  {
    path: 'nominate',
    name: 'recordNominate',
    component: RecordNominate,
    meta: {
      title: '推荐记录'
    }
  }
]
import Vue from 'vue'
import moment from 'moment'
import { local } from './index'
import enumBase from '@/enums/EnumBase'
import enumResume from '@/enums/EnumResume'
/**
 *
 * 时间过滤器 
 */
function DATE(time = new Date().getTime(), format = 'YYYY-MM-DD HH:mm') {
  if(time) {
    time = parseInt(time);
    return moment(time).format(format);
  } else {
    return '';
  }
}
/**
 * 地区过滤器
 */
let locationsObj = {};
export const CITY = (value = '') => {
  if(!Object.keys(locationsObj).length) {
		locationsObj = local.getObject('locationsObj');
	}
	return locationsObj[+value] && (locationsObj[+value].name || '');
}
export const CITYARR = (value = [], division = '') => {
  if(!Object.keys(locationsObj).length) {
		locationsObj = local.getObject('locationsObj');
	}
	if (!value || !value.length) {
		return '';
	}
	return Array.isArray(value) ? value.map(key => locationsObj[+key] && locationsObj[+key].name).join(division) : [];
}
export const FULLCITY = (value = '', format = 'pcd', division = '', isShowDirectly = true, isCode = false) => {
  if(!Object.keys(locationsObj).length) {
		locationsObj = local.getObject('locationsObj');
	}
	if(value) {
		let province = parseInt(`${value}`.substr(0, 2) + '0000');
		if (+value === province) {
			return locationsObj[province] && locationsObj[province].name;
		}
		let city = parseInt(`${value}`.substr(0, 4) + '00');
		let district = '';
		if (city !== +value) {
			district = +value;
		}
		// 直辖市不显示
		if ([110100, 310100, 120100, 500100].includes(city) && !isShowDirectly) {
			city = '';
		}
		if(isCode) {
			return format.split('').map(item => {
				switch(item) {
					case 'p':
						return province;
					case 'c':
						return city;
					case 'd':
						return district;
				}
			}).filter(item => item);
		}
    let provinceName = locationsObj[province] ? locationsObj[province].name : '';
    let cityName = locationsObj[city] ? locationsObj[city].name : '';
    let districtName = locationsObj[district] ? locationsObj[district].name : '';
    return format.replace('p', provinceName).replace('c', (locationsObj[city] && cityName) ? `${division}${cityName}` : '').replace('d', (locationsObj[district] && districtName) ? `${division}${districtName}` : '');
	} else {
		return '';
	}
}

export const FULLADDESS = (item) => {
	return FULLCITY(item.city, 'pcd', '-') + item.address;
}
// 性别
export const GENDER = code => {
  let gender = ['女', '男'];
  return gender[code] || '';
};

// 税前/税前
export const TAXTYPE = code => {
  return enumBase.TaxType[code] || '';
};

// 统招/非统招
export const NATIONALUNIFIED = code => {
  return enumBase.NationalUnified[code] || '';
};


// 学历
export const DEGREE = code => {
  const degreesObj = local.getObject('degreesObj');
  return degreesObj[code] ? degreesObj[code].name : '';
};
/**
 * 学历要求
 */
 let educationsObj = {};
 export const JOBDEGREE = code => {
	 if(!Object.keys(educationsObj).length) {
		 educationsObj = local.getObject('educationsObj');
	 }
	 return educationsObj[code] && educationsObj[code].name ? educationsObj[code].name.slice(0, 2) : '';
 }

 // 语言能力
export const LANGUAGE = (num) => {
	return num ? enumResume.Language[+num] : '';
}
// 语言等级
export const LANGUAGEGRADE = (num) => {
	return num ? enumResume.LanguageGrade[num] : '';
}
// 熟练程度
export const PROFICIENCY = (num) => {
	return num ? enumResume.Proficiency[num] : '';
}

// 缓存行业数据
let industrysObj = {};
// 行业过滤 code => 名称
export const INDUSTRY = (code, noShow = '', dataType = 'industrysObj') => {
	if(!Object.keys(industrysObj).length) {
		industrysObj = local.getObject(dataType);
	}
	return industrysObj[code] ? industrysObj[code].name : noShow;
}
// 行业数组类型
export const INDUSTRYARR = (arr, type) => {
	if(!Object.keys(industrysObj).length) {
		industrysObj = local.getObject('industrysObj');
	}
	let result = []
	if(Array.isArray(arr)) {
		arr && arr.forEach(value => {
			if (industrysObj[value]) {
				result.push(industrysObj[value].name)
			}
		})
	} else {
		result.push(industrysObj[arr].name)
	}
	return result.join(type || '，');
}
// 获取父级行业
export const PARENTINDUSTRY = code => {
	if(!code) {
		return '';
	}
	if(!Object.keys(industrysObj).length) {
		industrysObj = local.getObject('industrysObj');
	}
	let industry = industrysObj[code];
	if(!Object.keys(industry).length) {
		return '';
	}
	return industry.parentCode ? industrysObj[industry.parentCode] ? industrysObj[industry.parentCode].name : '' : industry.name;
}
/**
 * 职能过滤器
 */
let functionsObj = {};
export const FUNCTION = code => {
	if(!Object.keys(functionsObj).length) {
		functionsObj = local.getObject('functionsObj');
	}
	return functionsObj[code] ? functionsObj[code].name : '';
}
export const FUNCTIONARR = (value = [], division = '') => {
	if(!Object.keys(functionsObj).length) {
		functionsObj = local.getObject('functionsObj');
  }
  if(!value || !value.length) {
		return '';
	}
	return value.map(key => functionsObj[+key] && functionsObj[+key].name).join(division);
}
export const FUNCTIONALIAS = code => {
	if(code == 0) {
		return '其他';
	}
	if(!Object.keys(functionsObj).length) {
		functionsObj = local.getObject('functionsObj');
  }
  // 获取一级职能code
  let parentCode = (functionsObj[code] && functionsObj[code].parentCode) ? functionsObj[code].parentCode : code; 
  parentCode = (functionsObj[parentCode] && functionsObj[parentCode].parentCode) ? functionsObj[parentCode].parentCode : parentCode; 
  
  return functionsObj[parentCode] ? functionsObj[parentCode].name : '';
};

// 职位工作年限
let workYearsObj = {};
export const JOBWORKYEARS = code => {
	if(!Object.keys(workYearsObj).length) {
		workYearsObj = local.getObject('workYearsObj');
	}
	return workYearsObj[code] ? workYearsObj[code].name : '';
}

// 公司规模
let scalesObj = {};
export const COMPANYSCALE = code => {
	if(!Object.keys(scalesObj).length) {
		scalesObj = local.getObject('scalesObj');
	}
	return scalesObj[code] ? scalesObj[code].name : '';
}

// 文本转html
export const NEWLINE = (str) => {
	return str ? str.toString().replace(/\n/g, '<br>') : '';
}

// 生日转年龄
export const BIRTH2AGE = num => {
	return num ? new Date().getFullYear() - num : '';
}
// 简历工作年限
export const WORKYEARS = code => {
	return code >= 0 ? enumBase.WorkYears[code] : '';
}

// 数量99+
export const MORECOUNT = (code , num = 9999) => {
	return code > num ? `${num}+` : code;
}

export const TOLINK = (url, protocol = 'http') => {
  if(url.match(/http(s)?:/)) {
    return url;
  } else {
    return `${protocol}://${url}`
  }
}

Vue.use({
  install(Vue) {
    Vue.prototype.$n2br = (value = '') => {
			if(!value) {
				return '--';
			}
      return `${value || ''}`.trim().replace(/\n/g, '<br />');
    }
  }
});

export default {
  DATE,
  CITY,
  CITYARR,
  FULLCITY,
  GENDER,
	TAXTYPE,
	NATIONALUNIFIED,
	DEGREE,
	JOBDEGREE,
	LANGUAGE,
	LANGUAGEGRADE,
	PROFICIENCY,
	INDUSTRY,
	INDUSTRYARR,
	PARENTINDUSTRY,
	FUNCTION,
	FUNCTIONARR,
	FUNCTIONALIAS,
	JOBWORKYEARS,
	COMPANYSCALE,
	NEWLINE,
	BIRTH2AGE,
	WORKYEARS,
	FULLADDESS,
	MORECOUNT,
	TOLINK
}
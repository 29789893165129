import * as types from '../types'
import { local } from '../../utils'
// api
import * as baseData from '@/utils/api'

const state = {
  guideInfo: local.get('guideInfo') ? JSON.parse(local.get('guideInfo')) : '',
  guideUser: local.get('guideUser') ? JSON.parse(local.get('guideUser')) : '',
}

// getters
const getters = {
  guideInfo: state => state.guideInfo,
  guideUser: state => state.guideUser
}

// actions
const actions = {
  setGuideInfo({ commit }, queryParams) {
    const params = {};
    if(queryParams.preUrl) {
      params.preUrl = queryParams.preUrl;
    } else {
      params.url = queryParams.url;
    }
    return baseData.getGuideInfo(params).then(data => {
      if(data) {
        commit('setGuideInfo', data);
        return data;
      }
    })
  },
  mockUserLogin({ commit }, queryParams) {
    return baseData.mockUserLogin(queryParams).then(data => {
      if(data) {
        commit('setGuideUser', data);
        return data;
      }
    });
  }
}

// mutations
const mutations = {
  setGuideInfo: (state, data) => {
    state.guideInfo = data;
    local.set('guideInfo', JSON.stringify(data));
  },
  setGuideUser: (state, data) => {
    state.guideUser = data;
    local.set('guideUser', JSON.stringify(data));
  },
  clearGuideUser:(state, data) => {
    state.guideUser = '';
    local.remove('guideUser');
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
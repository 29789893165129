/**
 * 定义改变状态方法常量
 */
export const BASE_DATA = 'BASE_DATA' // 基础数据

export const SET_ROOT_PATH = 'SET_ROOT_PATH' // 根路径

export const SET_UCCN_INFO = 'SET_UCCN_INFO' // 设置访问网站主要信息

export const CLEAR_UCCN_INFO = 'CLEAR_UCCN_INFO' // 清除访问网站主要信息

export const SET_USER_INFO = 'SET_USER_INFO' // 设置登录用户信息

export const CLEAR_USER_INFO = 'CLEAR_USER_INFO' // 清除用户登录信息
import { $http } from './http';
import { utils } from './index'

// 获取网站基础信息
export const getUccnInfo = websiteId => {
	return $http.get('/api/website/main/info.json', {params: {
		websiteId: websiteId
	}});	
}

// 获取用户信息
export const getUserInfo = websiteId => {
	return $http.get('/api/internal/recom/person/check.json');	
}

// 获取指南基础信息
export const getGuideInfo = ({ url, preUrl}) => {
	return $http.get('/api/manual/guide/info.json', {params: utils.deleteKeys({
		url: url,
		preUrl: preUrl
	})});	
}

// 获取模拟登录账号
export const mockUserLogin = preUrl => {
	return $http.get('/api/manual/guide/mock/login.json', {params: {
		preUrl: preUrl
	}});
}

// 基础数据版本
export const getBaseData = () => {
	return $http.get('/api/basedata/version.json');	
}
// 热门城市
export const getHotLocations = () => {
	return $http.get('/api/basedata/hotlocations.json');	
}
// 城市
export const getLocations = () => {
	return $http.get('/api/basedata/locations.json');	
}
// 行业
export const getIndustrys = () => {
	return $http.get('/api/basedata/industrys.json');	
}
// 职能
export const getFunctions = () => {
	return $http.get('/api/basedata/seniorFunction.json');	
}
// 学历
export const getDegrees = () => {
	return $http.get('/api/basedata/degrees.json');	
}
// 工作年限
export const getWorkYears = () => {
	return $http.get('/api/basedata/work/year.json');	
}
// 职位学历
export const getEducations = () => {
	return $http.get('/api/basedata/education.json');	
}
// 企业规模
export const getScales = () => {
	return $http.get('/api/basedata/scale.json');	
}

// 语言
export const getLanguage = () => {
	return $http.get('/api/basedata/language.json');
}
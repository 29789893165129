/*
======
认证信息
======
*/
// 身份认证
const IdentityAll = () => import(/* webpackChunkName: 'identity' */ '@/views/identity/all');
// 身份认证-企业员工
const IdentityEmployee = () => import(/* webpackChunkName: 'identity' */ '@/views/identity/employee');
// -邮箱认证
const IdentityEmail = () => import(/* webpackChunkName: 'identity' */ '@/views/identity/email');
// -信息完善
const IdentityNews = () => import(/* webpackChunkName: 'identity' */ '@/views/identity/news');
// -IdentityOut
const IdentityOut = () => import(/* webpackChunkName: 'identity' */ '@/views/identity/out');

export default [
  {
    path: 'all',
    name: 'identityAll',
    component: IdentityAll,
    meta: {
      title: '身份认证'
    }
  },
  {
    path: 'employee',
    name: 'identityEmployee',
    component: IdentityEmployee,
    meta: {
      title: '身份认证'
    }
  },
  {
    path: 'email',
    name: 'identityEmail',
    component: IdentityEmail,
    meta: {
      title: '身份认证'
    }
  },
  {
    path: 'news',
    name: 'identityNews',
    component: IdentityNews,
    meta: {
      title: '身份认证'
    }
  },
  {
    path: 'out',
    name: 'identityOut',
    component: IdentityOut,
    meta: {
      title: '身份认证'
    }
  }
]
//手机号归属
export const PhoneArea = {
  1: '+86',
  2: '国外'
};
//性别
export const GenderType = {
  0: '女',
  1: '男'
};
// 税前税后
export const TaxType = {
	1: '税前',
  2: '税后'
};
// 统招/非统招
export const NationalUnified = {
	1: '统招',
  0: '非统招'
};
// 工作经验
export const WorkYears = {
	0: '1年以内',
	1: '1年',
	2: '2年',
	3: '3年',
	4: '4年',
	5: '5年',
	6: '6年',
	7: '7年',
	8: '8年',
	9: '9年',
	10: '10年',
	11: '11年',
	12: '12年',
	13: '12年以上',
};

export const NavBgColor = {
  1: '#FF3E38',
  2: '#FF6E27',
  3: '#FFBF27',
  4: '#9FFF08',
  5: '#08FF8E',
  6: '#08B1FF',
  7: '#2755FF',
  8: '#FF0DD4',
  9: '#A627FF',
  10: '#E5EBF1',
	11:'#0A5F48', 
	12: '#FFFFFF',
	13: 'rgba(255, 255, 255, 0)'
};

export default {
	NavBgColor,
	PhoneArea,
	GenderType,
	TaxType,
	NationalUnified,
	WorkYears
}
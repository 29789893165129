import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import { local, utils } from '@/utils'

// 个人路由
import User from './user'
// 身份认证
import Identity from './identity'
// 简历路由
import Resume from './resume'
// 职位路由
import Job from './job'
// 记录路由
import Record from './record'
// 叫号面试路由
import Queue from './queue'
// 入职指南
import Guide from './guide'

// 未建站
const Absence = () => import(/* webpackChunkName: 'index' */ '@/views/absence');
// 首页
const Home = () => import(/* webpackChunkName: 'index' */ '@/views/home');
// 登录
const Login = () => import(/* webpackChunkName: 'index' */ '@/views/login');
// 个人信息
const UserIndex = () => import(/* webpackChunkName: 'index' */ '@/views/user');
// 身份认证
const IdentityIndex = () => import(/* webpackChunkName: 'index' */ '@/views/identity');
// 简历
const ResumeIndex = () => import(/* webpackChunkName: 'index' */ '@/views/resume');
// 职位
const JobIndex = () => import(/* webpackChunkName: 'index' */ '@/views/job');
// 记录
const RecordIndex = () => import(/* webpackChunkName: 'index' */ '@/views/record');
// 叫号
const QueueIndex = () => import(/* webpackChunkName: 'index' */ '@/views/queue');

// 我的推荐
const MyRecommend = () => import(/* webpackChunkName: 'index' */ '@/views/recommend');
// 投递进展
const DeliveryProgress = () => import(/* webpackChunkName: 'index' */ '@/views/delivery');
// 入职指南
const GuideIndex = () => import(/* webpackChunkName: 'index' */ '@/views/guide');

let route = [
  {
    path: '/absence',
    name: 'Absence',
    component: Absence,
    meta: {
      title: '未建站'
    }
  },
  {
    path: '/*/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/user',
    name: 'user',
    component: UserIndex,
    redirect: {
      name: 'userPersonal'
    },
    children: User
  },
  {
    path: '/identity',
    name: 'identity',
    component: IdentityIndex,
    redirect: {
      name: 'identityAll'
    },
    children: Identity
  },
  { path: '/resume',
    name: 'resume',
    component: ResumeIndex,
    redirect: {
      name: 'ResumeAdd'
    },
    children: Resume
  },
  { path: '/job',
    name: 'job',
    component: JobIndex,
    redirect: {
      name: 'jobList'
    },
    children: Job
  },
  {
    path: '/record',
    name: 'record',
    component: RecordIndex,
    redirect: {
      name: 'recordReferralCode'
    },
    children: Record
  },
  {
    path: '/queue',
    name: 'queue',
    component: QueueIndex,
    redirect: {
      name: 'queueApply'
    },
    meta: {
      title: '叫号面试签到'
    },
    children: Queue
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: MyRecommend,
    meta: {
      title: '推荐数据'
    }
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: DeliveryProgress,
    meta: {
      title: '投递进展'
    }
  },
  {
    path: '/guide',
    name: 'guide',
    component: GuideIndex,
    meta: {
      title: '投递进展'
    },
    children: Guide
  },
  {
    path: '/*',
    redirect: {
      name: 'Absence'
    }
  }
];

// 解决重复点击菜单会报错
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push() {
//   return originalPush.call(this, location).catch(err => err);
// }
// 命名路由
const router = new Router({
  mode: 'history',
  routes: route,
  linkActiveClass: 'active',
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
});

router.beforeEach(async(to, from, next) => {
  // 先获取基础数据
  if(!store.getters.locations || !store.getters.locations) {
    await store.dispatch('BASE_DATA');
  }
  if(to.name.includes('guide')) {
    if(to.name === 'guideHome') {
      await store.dispatch('setGuideInfo', { url: to.params.url, preUrl: to.query.previewUrl });
      if(to.query.previewUrl) {
        await store.dispatch('mockUserLogin', to.query.previewUrl);
      } else {
        if(store.getters.guideUser && store.getters.guideUser.mockFlag) {
          store.commit('clearGuideUser');
        }
      }
    }
    // 入职指南信息不存在
    if(!store.getters.guideInfo || store.getters.guideInfo?.delFlag || !store.getters.guideInfo?.status) {
      next({ name: 'Absence'});
    }
    // 未登录拦截
    if(to.name !== 'guideLogin' && !store.getters.guideUser) {
      next({ name: 'guideLogin' });
    }
  } else {
    // 判断没菜单重新获取 to.query.websiteId = -1 为激活候选人
    if(to.query.websiteId < 0) {
      store.commit('CLEAR_UCCN_INFO');
      store.commit('CLEAR_USER_INFO');
    }
    if(to.query.websiteId > 0 && (!store.getters.uccnInfo || (store.getters.uccnInfo && store.getters.uccnInfo.id != to.query.websiteId))) {
      await store.dispatch('setUccnInfo', to.query.websiteId);
      // 如果进入另外一个公司，则清除之前公司登录信息 同一公司的不同类型官网也清除登录信息
      if(local.get('user') && ((JSON.parse(local.get('uccnInfo') || '{}')).companyId !== (JSON.parse(local.get('user') || '{}')).companyId || (JSON.parse(local.get('uccnInfo') || '{}')).type !== (JSON.parse(local.get('user') || '{}')).type)) {
        store.commit('CLEAR_USER_INFO');
      }
    }
    // 校招宣讲会签到
    if(to.query.source && to.query.source === 'school') {
      // console.log(to);
      store.dispatch('getUserInfo');
    }
    // 内推官网增加注册员工的入口跳转到登录页
    if(to.name.indexOf('login') === -1 && to.name.indexOf('Home') === -1 && to.params && !!to.params.pathMatch && to.params.pathMatch.indexOf('register') !== -1) {
      if(utils.isPhone()) {
        next({
          name: 'login',
          query: {isRecommendTop: true},
          params: {pathMatch: to.params.pathMatch.split('/')[0]}
        });
      } else {
        next({
          name: 'Home',
          query: {pageFrom: 'register'},
          params: {pathMatch: to.params.pathMatch.split('/')[0]}
        });
      }
    }
  }
  next();
});

Vue.use(Router);

export default router
/**
 * 异步改变状态，通过commit 触发 mutations， 不能直接改变state状态
 * 默认接收context 参数
 * increment (context) {
 *    context.commit('increment')
 * }
 * 也可以通过结构赋值方式传递参数 context下面有：state、getters、commit、dispatch
 * increment ({ commit }) {
 *   commit('increment')
 * }
 *
 *
 * 调用方式：store.dispatch('increment')
 *
 * 除了触发方式名称不同（commit <=> dispatch）, 其余使用与 commit 一致
 *
 */
// api
import * as baseData from '@/utils/api'
// 缓存
import { local, utils } from '@/utils'

export default {
  // 热门城市
  hotLocations({ commit }) {
    baseData.getHotLocations().then(data => {
      if(data) {
        local.setObject('hotLocations', data);
        commit('hotLocations', data);
      }
    })
  },
  // 地区
  locations({ commit }) {
    baseData.getLocations().then(data => {
      if(data) {
        local.setObject('locations', data);
        commit('locations', data);

        let obj = utils.repeat(data, 'code');
        local.setObject('locationsObj', obj);
        commit('locationsObj', obj);
      }
    })
  },
  // 行业
  industrys({ commit }) {
    baseData.getIndustrys().then(data => {
      if(data) {
        local.setObject('industrys', data);
        commit('industrys', data);

        let obj = utils.repeat(data, 'code');
        local.setObject('industrysObj', obj);
        commit('industrysObj', obj);
      }
    })
  },
  // 职能
  functions({ commit }) {
    baseData.getFunctions().then(data => {
      if(data) {
        local.setObject('functions', data);
        commit('functions', data);

        let obj = utils.repeat(data, 'code');
        local.setObject('functionsObj', obj);
        commit('functionsObj', obj);
      }
    })
  },
  // 学历
  degrees({ commit }) {
    baseData.getDegrees().then(data => {
      if(data) {
        local.setObject('degrees', data);
        commit('degrees', data);

        let obj = utils.repeat(data, 'code');
        local.setObject('degreesObj', obj);
        commit('degreesObj', obj);
      }
    })
  },
  // 工作年限
  workYears({ commit }) {
    baseData.getWorkYears().then(data => {
      if(data) {
        local.setObject('workYears', data);
        commit('workYears', data);

        let obj = utils.repeat(data, 'code');
        local.setObject('workYearsObj', obj);
        commit('workYearsObj', obj);
      }
    })
  },

  // 学历
  educations({ commit }) {
    baseData.getEducations().then(data => {
      if(data) {
        local.setObject('educations', data);
        commit('educations', data);

        let obj = utils.repeat(data, 'code');
        local.setObject('educationsObj', obj);
        commit('educationsObj', obj);
      }
    })
  },

  // 企业规模
  scales({ commit }) {
    baseData.getScales().then(data => {
      if(data) {
        local.setObject('scales', data);
        commit('scales', data);

        let obj = utils.repeat(data, 'code');
        local.setObject('scalesObj', obj);
        commit('scalesObj', obj);
      }
    })
  },

  // 语言
  languages({ commit }) {
    baseData.getLanguage().then(data => {
      if(data) {
        local.setObject('languages', data);
        commit('languages', data);
      }
    })
  },
  // 检测数据是否存在
  detection({ commit, dispatch }) {
    let hotLocations = local.getObject('hotLocations'),
      locations = local.getObject('locations'),
      locationsObj = local.getObject('locationsObj'),
      industrys = local.getObject('industrys'),
      industrysObj = local.getObject('industrysObj'),
      functions = local.getObject('functions'),
      functionsObj = local.getObject('functionsObj'),
      degrees = local.getObject('degrees'),
      degreesObj = local.getObject('degreesObj'),
      workYears = local.getObject('workYears'),
      workYearsObj = local.getObject('workYearsObj'),
      educations = local.getObject('educations'),
      educationsObj = local.getObject('educationsObj'),
      scales = local.getObject('scales'),
      scalesObj = local.getObject('scalesObj'),
      languages = local.getObject('languages');

    !Object.keys(hotLocations).length ? dispatch('hotLocations') : commit('hotLocations', hotLocations);
    !Object.keys(languages).length ? dispatch('languages') : commit('languages', languages);

    if(!Object.keys(locations).length) {
      dispatch('locations');
    } else {
      commit('locations', locations);
      commit('locationsObj', locationsObj);
    }
    if(!Object.keys(industrys).length) {
      dispatch('industrys');
    } else {
      commit('industrys', industrys);
      commit('industrysObj', industrysObj);
    }
    if(!Object.keys(functions).length) {
      dispatch('functions');
    } else {
      commit('functions', functions);
      commit('functionsObj', functionsObj);
    }
    if(!Object.keys(degrees).length) {
      dispatch('degrees');
    } else {
      commit('degrees', degrees);
      commit('degreesObj', degreesObj);
    }
    if(!Object.keys(workYears).length) {
      dispatch('workYears');
    } else {
      commit('workYears', workYears);
      commit('workYearsObj', workYearsObj);
    }
    if(!Object.keys(educations).length) {
      dispatch('educations');
    } else {
      commit('educations', educations);
      commit('educationsObj', educationsObj);
    }
    if(!Object.keys(scales).length) {
      dispatch('scales');
    } else {
      commit('scales', scales);
      commit('scalesObj', scalesObj);
    }
  },
  // 获取基础数据版本号
  BASE_DATA({ state, dispatch }) {
    let preVersion = local.getObject('version');
    baseData.getBaseData().then(data => {
      if(data) {
        // 版本号不相等的就把数据删除
        for (let name in data) {
          if (preVersion[name] != data[name]) {
            // 字段转化
            switch(name) {
              case 'city':
                name = 'locations';
                break;
              case 'industry':
                name = 'industrys';
                break;
              case 'seniorFunction':
                name = 'functions';
                break;
              case 'education':
                  name = 'degrees';
                  break;
              case 'positionWorkYear':
                name = 'workYears';
                break;
              case 'positionEducation':
                name = 'educations';
                break;
              case 'companyScale':
                name = 'scales';
                break;
              case 'languageCategory':
                name = 'languages';
                break;
            }
            local.remove(name);
            if(name === 'locations') {
              local.remove('hotLocations'); //城市和热门城市使用同一个版本
            }
          }
        }
        // 设置最新版本 
        local.setObject('version', data);
        // 触发检测方法
        dispatch('detection');
      }
    })
  }
};
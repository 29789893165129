/*
======
个人信息
======
*/
// 个人中心
const UserPersonal = () => import(/* webpackChunkName: 'user' */ '@/views/user/personal');
// 校园大使-身份认证
const UserAuthAmbassador = () => import(/* webpackChunkName: 'user' */ '@/views/user/authAmbassador');
// 生成推荐码
const UserCreateUpdated = () => import(/* webpackChunkName: 'user' */ '@/views/user/createUpdated');

export default [
  {
    path: 'personal',
    name: 'userPersonal',
    component: UserPersonal,
    meta: {
      title: '个人中心'
    }
  },
  {
    path: 'auth/ambassador',
    name: 'userAuthAmbassador',
    component: UserAuthAmbassador,
    meta: {
      title: '身份认证'
    }
  },
  {
    path: 'createupdated',
    name: 'userCreateUpdated',
    component: UserCreateUpdated,
    meta: {
      title: '生成推荐码'
    }
  }
]
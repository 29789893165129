<template>
  <el-dialog :visible="show" :before-close="close" :close-on-click-modal="false" center class="industry-box">
    <div class="industry">
      <h2 class="title">选择行业分类（{{tips}}）</h2>
      <div class="industry-list">
        <div class="industry-row" v-for="item in data" :key="item.code">
          <div class="row-title">
            <a class="industry-item" :class="{'unselectable': !firstSelectable, active: selectedVal.includes(item.code)}" href="javascript:;" @click="selected(item.code, firstSelectable)">
              <span>{{item.name}}</span>
            </a>
          </div>
          <div class="row-content">
            <a class="industry-item" :class="{active: selectedVal.includes(val.code)}" v-for="val in item.children" :key="val.code" href="javascript:;" @click="selected(val.code)">
              <span>{{val.name}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button class="btn ensure" type="primary" @click="save">确定</el-button>
      <el-button class="btn" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { Dialog, Button, Message} from 'element-ui'
  import './main.scss' // 引入样式

  export default {
    name: 'Industry',
    components: {
      [Dialog.name]: Dialog,
      [Button.name]: Button,
      [Message.name]: Message
    },
    data() {
      return {
        max: 1,
        show: true,
        data: this.$utils.deepClone(this.$local.getObject('industrys')),
        selectedVal: [],
        firstSelectable: false, 
        callback: function() {}
      };
    },
    computed: {
      tips() {
        return this.max === 1 ? '单选' : `最多选择${this.max}个`;
      }
    },
    methods: {
      init(opt) {
        this.show = true;
        this.max = opt.max ? opt.max : this.max;
        this.firstSelectable = opt.firstSelectable ? opt.firstSelectable : this.firstSelectable;
        this.callback = opt.callback ? opt.callback : this.callback;
        this.dafaultSelect(opt.defaultVal);
      },
      close() {
        this.show = false;
      },
      selected(code, isSelectable = true) {
        let industrysObj = this.$local.getObject('industrysObj');
        if (this.selectedVal.includes(code)) {
          this.selectedVal.splice(this.selectedVal.indexOf(code), 1);
          return;
        }

        if(!isSelectable) return;

        if(this.firstSelectable) {
          let parentCode = industrysObj[code].parentCode;
          this.selectedVal = this.selectedVal.filter(item => {
            // 子项 取消 一级
            if(parentCode) {
              return item !== parentCode
            }
            // 一级 取消 子项
            return industrysObj[item].parentCode !== code;
          });
        }
        if (this.selectedVal.length >= this.max) {
          Message.error(`最多选择${this.max}个`);
          return false;
        }
        this.selectedVal.push(code);
      },
      remove(index, code) {
        this.selectedVal.splice(index, 1);
      },
      save() {
        if (this.selectedVal.length) {
          this.callback(this.$utils.deepClone(this.selectedVal));
          this.show = false;
        } else {
          Message.error('请至少选择1个');
        }
      },
      dafaultSelect(data) {
        this.selectedVal = [];
        data && data.length && data.forEach(item => {
          this.selectedVal.push(+item);
        });
      }
    }
  };
</script>
/*
======
叫号面试信息
======
*/
// 叫号面试签到 & 现场申请
const QueueApply = () => import(/* webpackChunkName: 'queue' */ '@/views/queue/apply');
// 多个项目选择
const QueueProjects = () => import(/* webpackChunkName: 'queue' */ '@/views/queue/projects');
// 现场签到职位列表
const QueueJobs = () => import(/* webpackChunkName: 'queue' */ '@/views/queue/jobs');
// 签到成功
const QueueApplySucceed = () => import(/* webpackChunkName: 'queue' */ '@/views/queue/succeed');
// 现场签到投递简历
const QueueAddResume = () => import(/* webpackChunkName: 'queue' */ '@/views/queue/addResume');

export default [
  {
    path: 'apply',
    name: 'queueApply',
    component: QueueApply,
    meta: {
      title: '叫号面试签到' // 1-叫号面试签到; 2-现场申请
    }
  },
  {
    path: 'projects',
    name: 'queueProjects',
    component: QueueProjects,
    meta: {
      title: '叫号面试签到'
    }
  },
  {
    path: 'jobs',
    name: 'queueJobs',
    component: QueueJobs,
    meta: {
      title: '现场申请'
    }
  },
  {
    path: 'apply/succeed',
    name: 'queueApplySucceed',
    component: QueueApplySucceed,
    meta: {
      title: '叫号面试签到'
    }
  },
  {
    path: 'apply/addresume',
    name: 'queueAddResume',
    component: QueueAddResume,
    meta: {
      title: '现场申请',
      keepAlive: true
    }
  }
]
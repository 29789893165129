import * as types from '../types'
import { local } from '../../utils'
// api
import * as baseData from '@/utils/api'

const state = {
  rootPath: local.get('rootPath') || '',
  uccnInfo: local.get('uccnInfo') ? JSON.parse(local.get('uccnInfo')) : '',
}

// getters
const getters = {
  rootPath: state => state.rootPath,
  uccnInfo: state => state.uccnInfo
}

// actions
const actions = {
  setUccnInfo({ commit }, queryParams) {
    return baseData.getUccnInfo(queryParams).then(data => {
      if(data) {
        commit(types.SET_UCCN_INFO, data);
        if(data.websiteUrl) {
          commit(types.SET_ROOT_PATH, data.websiteUrl);
        }
      }
    })
  }
}

// mutations
const mutations = {
  [types.SET_ROOT_PATH]:(state, data) => {
    state.rootPath = data;
    local.set('rootPath', data);
  },
  [types.SET_UCCN_INFO]:(state, data) => {
    state.uccnInfo = data;
    local.set('uccnInfo', JSON.stringify(data));
  },
  [types.CLEAR_UCCN_INFO]:(state, data) => {
    state.uccnInfo = '';
    local.remove('uccnInfo');
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
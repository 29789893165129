import Area from './main.vue'
/**
 * 每个插件都有的install方法，用于安装插件
 * @param {Object} Vue - Vue类
 * @param {Object} [pluginOptions] - 插件安装配置
 */
Area.install = function(Vue, pluginOptions = {}) {
  // 创建"子类"方便挂载
  const VueMain = Vue.extend(Area)
  let area = null
  /**
   * 初始化并显示
   * @returns {Promise} Promise实例
   */
  function $area(opt) {
    //!!!!!!!!!!!!!!!! 这边是重点 !!!!!!!!!!!!!!!!
    // 第一次调用
    if (!area) {
      area = new VueMain()
      // 手动创建一个未挂载的实例
      area.$mount()
      // 挂载
      document.querySelector(pluginOptions.container || 'body').appendChild(area.$el)
    }
    area.init(opt);
  }
  Vue.prototype.$area = $area;
};

export default Area;

// 引入axios
import Axios from 'axios'
import queryString from 'querystring'
import { Toast } from 'vant'
import $store from '@/store'
import { utils, local } from '@/utils'

let axios = Axios.create();
// 超时时间30s
axios.defaults.timeout = 30000;
// 请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
// http请求拦截器
axios.interceptors.request.use(config => {
  //POST数据转换 上传文件时不做处理
  if(config.headers['Content-Type'] !== 'multipart/form-data') {
    let data = config.data;
    config.data = queryString.stringify(data);
  }
  return config
}, error => {
  try {
    return Promise.reject(error);
  } catch(err) {
    console.error(err);
  }
});

// http响应拦截器
axios.interceptors.response.use(res => {
  // 统一处理服务端异常错误
  if(!res.config.global) {
    if(+res.data.status === 200 && res.data) {
      return res.data.data;
    } else {
      if(res.data.status === 'error_00005') {
        location.href = '/absence';
      } else if(+res.data.status === 999) {
        let errMsg = '用户未登录';
        if(res.data && res.data.message) {
          errMsg = res.data.message;
        }
        Toast.fail({
          message: errMsg,
          duration: 3000
        });
        $store.commit('clearGuideUser');
        window.location.href = window.location.origin + '/guide/login';
      } else {
        Toast.fail({
          message: res.data.message || '出错了！但后台未返回错误信息',
        });
      }
      return Promise.reject(res.data);
    }
  }
  return res
}, error => {
  try {
    // 返回状态
    if(error.response) {
      switch (error.response.status) {
        case 404:
          Toast.fail({
            message: '请求接口不存在',
            duration: 3000
          });
          break;
        case 500:
          Toast.fail({
            message: '网络连接失败',
            duration: 3000
          });
          break;
        default:
          Toast.fail({
            message: '错误码：' + error.response.status,
            duration: 3000
          });
          break;
      }
    }
    // 请求状态
    if(error.request) {
      switch (error.request.status) {
        case 0:
          Toast.fail({
            message: error.data.message || '请求超时',
            duration: 3000
          });
          break;
        case 401:
          Toast.fail({
            message: '请登录后访问',
            duration: 3000
          });
          break;
        case 404:
          Toast.fail({
            message: '请求接口不存在',
            duration: 3000
          });
          break;
        case 500:
          Toast.fail({
            message: '网络连接失败',
            duration: 3000
          });
          break;
        default:
          Toast.fail({
            message: error.data.message,
            duration: 3000
          });
          break;
      }
    }
  } catch(error) {
    Toast.fail({
      message: error,
      duration: 3000
    });
  }
  return Promise.reject(error);
});

export const $guideHttp = axios;

export default {
  install: function (vm) {
    vm.prototype.$guideHttp = axios
  }
}
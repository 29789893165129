/**
 * ！！！ 所有改变状态都是同步进行，如果异步请查看actions
 * 改变state状态 默认第一个参数接收当前state ，第二个参数可以自定义，一般多为对象，主要原因是对象内可以存储更多的参数
 * 调用方式： store.commit('定义的改变状态方法名' [, arg])
 *
 * 对象风格提交
 *  store.commit({
 *    type: 'showLoading',
 *    amount: 10
 *  })
 *  
 *  👇
 *  showLoading: (state, payload) => {
 *     state.num = payload.amount
 *  },
 *
 *
 */
export default {
  hotLocations: (state, data) => {
    state.hotLocations = data;
  },
  locations: (state, data) => {
    state.locations = data;
  },
  locationsObj: (state, data) => {
    state.locationsObj = data;
  },
  industrys: (state, data) => {
    state.industrys = data;
  },
  industrysObj: (state, data) => {
    state.industrysObj = data;
  },
  functions: (state, data) => {
    state.functions = data;
  },
  functionsObj: (state, data) => {
    state.functionsObj = data;
  },
  degrees: (state, data) => {
    state.degrees = data;
  },
  degreesObj: (state, data) => {
    state.degreesObj = data;
  },
  workYears: (state, data) => {
    state.workYears = data;
  },
  workYearsObj: (state, data) => {
    state.workYearsObj = data;
  },
  educations: (state, data) => {
    state.educations = data;
  },
  educationsObj: (state, data) => {
    state.educationsObj = data;
  },
  scales: (state, data) => {
    state.scales = data;
  },
  scalesObj: (state, data) => {
    state.scalesObj = data;
  },
  languages: (state, data) => {
    state.languages = data;
  },
}
<template>
  <div id="app" class="main-wrap" :class="{'business-style': uccnInfo.style === 2 }">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['uccnInfo']),
  },
  created() {
    this.$store.dispatch('BASE_DATA');
  }
}
</script>

<style lang="scss">
  html, body, .main-wrap {
    height: 100%;
  }
  .business-style { // 简约商务全部无圆角
    *, *::after, *::before {
      border-radius: 0 !important;
    }
  }
</style>

import Vue from 'vue'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'
import Http from '@/utils/http'
import GuideHttp from '@/utils/guideHttp'
import Bus from '@/utils/bus'
import Brand from '@/utils/brand'
import utils from '@/utils'
import router from '@/router'
import store from './store'
import Area from '@/components/pc/area'
import Functions from '@/components/pc/functions'
import Industry from '@/components/pc/industry'
import Bases from '@/components/pc/base/index.js'
import { Loading } from 'element-ui'
import filters from './utils/filters'

// 遍历注册全局基础组件
Object.keys(Bases).forEach(key => Vue.component(key, Bases[key]))
// 遍历注册全局过滤器
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(Http);
Vue.use(GuideHttp);
Vue.use(Bus);
Vue.use(Brand);
Vue.use(utils);
Vue.use(Area);
Vue.use(Functions);
Vue.use(Industry);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

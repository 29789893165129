export default {
  // 热门城市
  hotLocations(state) {
    return state.hotLocations;
  },
  // 城市
  locations(state) {
    return state.locations;
  },
  locationsObj(state) {
    return state.locationsObj;
  },
  // 行业
  industrys(state) {
    return state.industrys;
  },
  industrysObj(state) {
    return state.industrysObj;
  },
  // 职能
  functions(state) {
    return state.functions;
  },
  functionsObj(state) {
    return state.functionsObj;
  },
  // 学历
  degrees(state) {
    return state.degrees;
  },
  degreesObj(state) {
    return state.degreesObj;
  },
  // 工作年限
  workYears(state) {
    return state.workYears;
  },
  workYearsObj(state) {
    return state.workYearsObj;
  },
  // 职位学历
  educations(state) {
    return state.educations;
  },
  educationsObj(state) {
    return state.educationsObj;
  },
  // 公司规模
  scales(state) {
    return state.scales;
  },
  scalesObj(state) {
    return state.scalesObj;
  },
  languages() {
    return state.languages;
  }
}
/*
======
简历信息
======
*/
// 投递填写
const ResumeAdd = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/add');
// 添加工作经历
const WorkExp = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/workExp');
// 添加教育经历
const EducationExp = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/educationExp');
// 添加附件作品
const Products = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/products');
// 添加自定义模块
const CustomExp = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/customExp');
// 添加项目经历
const ProjectExp = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/projectExp');
// 添加培训经历
const TrainExp = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/trainExp');
// 添加语言经历
const LanguageExp = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/languageExp');
// 投递简历页面
const ResumeDelivery = () => import(/* webpackChunkName: 'resume' */ '@/views/resume/delivery');
// 简历投递成功
const DeliverySuccess = () => import(/* webpackChunkName: 'index' */ '@/views/resume/deliverySuccess');

export default [
  {
    path: 'delivery',
    name: 'ResumeDelivery',
    component: ResumeDelivery,
    meta: {
      title: '投递简历'
    }
  },
  {
    path: 'add',
    name: 'ResumeAdd',
    component: ResumeAdd,
    meta: {
      title: '简历投递',
      keepAlive: true
    }
  },
  {
    path: 'workExp',
    name: 'WorkExp',
    component: WorkExp,
    meta: {
      title: '添加工作经历'
    }
  },
  {
    path: 'educationExp',
    name: 'EducationExp',
    component: EducationExp,
    meta: {
      title: '添加教育经历'
    }
  },
  {
    path: 'projectExp',
    name: 'ProjectExp',
    component: ProjectExp,
    meta: {
      title: '添加项目经历'
    }
  },
  {
    path: 'products',
    name: 'Products',
    component: Products,
    meta: {
      title: '添加附件作品'
    }
  },
  {
    path: 'customExp',
    name: 'CustomExp',
    component: CustomExp,
    meta: {
      title: '添加自定义'
    }
  },
  {
    path: 'trainExp',
    name: 'TrainExp',
    component: TrainExp,
    meta: {
      title: '添加培训经历'
    }
  },
  {
    path: 'languageExp',
    name: 'LanguageExp',
    component: LanguageExp,
    meta: {
      title: '添加语言经历'
    }
  },
  {
    path: 'my',
    name: 'myResume',
    component: ResumeAdd,
    meta: {
      title: '我的简历',
      keepAlive: true
    }
  },
  {
    path: 'deliverysuccess',
    name: 'deliverySuccess',
    component: DeliverySuccess,
    meta: {
      title: '投递成功'
    }
  },
]